<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
            <a-form layout="inline">
              <a-form-model-item>
                <a-input v-model="page.key"  placeholder="请输入关键字"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.company_id" placeholder="请选择公司">
                  <a-select-option v-for="(item, index) in companyList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select allowClear style="width: 200px" v-model="page.admin_id" placeholder="请选择销售">
                  <a-select-option v-for="(item, index) in salesList" :value="item.id" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-button type="primary" @click="search">搜索</a-button>
              </a-form-model-item>
            </a-form>
          </div>
          <div class="page-cu-left-table">
            <vuescroll>
              <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
                <template slot="is_apply" slot-scope="text, record">
                    <span v-if="text == '1'">未审核</span>
                    <span v-if="text == '2'">审核通过</span>
                    <span v-if="text == '3'">审核拒绝</span>
                    <span v-if="text == '4'">申请编辑中</span>
                    <span v-if="text == '5'">可编辑</span>
                    <span v-if="text == '6'">申请删除中</span>
                </template>
                <span slot="action" slot-scope="text, record">
                  <a-button type="eye" @click="openDialog(record.id)" icon="edit">跟进记录</a-button>
                </span>

              </a-table>
              <div class="page-cu-left-page">
                <a-pagination
                  v-model="page.start"
                  :page-size.sync="page.pageSize"
                  :total="page.totalRow"
                  @change="changePage"
                />
              </div>
            </vuescroll>
          </div>
        </a-col> 
      </a-row>
    </div>

    <a-modal title="客户跟进记录" :width="800" :destroyOnClose="true" :confirmLoading="$store.state.requestLoading" :visible="actionVisible" :footer="null" @cancel="cancel">
         <a-table :columns="folloWrecords" :data-source="followUpList" rowKey="id" bordered :pagination="false">
         </a-table>
         <div class="page-cu-left-page">
                <a-pagination
                  v-model="recordPage.start"
                  :page-size.sync="recordPage.pageSize"
                  :total="recordPage.totalRow"
                  @change="changeRecordPage"
                />
        </div>
    </a-modal>
  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {
        columns: [
          {
            title: '所属公司',
            dataIndex: 'company_name',
          },
          {
            title: '所属部门',
            dataIndex: 'department'
          },
          {
            title: '所属销售',
            dataIndex: 'admin_name'
          },
          {
            title: '联系人',
            dataIndex: 'name',
          },
          {
            title: '联系电话',
            dataIndex: 'mobile',
          },
          {
            title: '职位',
            dataIndex: 'position'
          },
          {
            title: '客户状态',
            dataIndex: 'status'
          },
          {
            title: '审核状态',
            dataIndex: 'is_apply',
            scopedSlots: {customRender: 'is_apply'},
          },
          {
            title: '创建时间',
            dataIndex: 'create_time',
            width:200
          },
          {
            title: '操作',
            width: 150,
            scopedSlots: {customRender: 'action'},
          },
        ],
        // 跟进记录
        folloWrecords: [
          {
            title: '跟进人姓名',
            dataIndex: 'admin_name',
          },
          {
            title: '联系电话',
            dataIndex: 'admin_mobile',
          },
          {
            title: '跟进形式',
            dataIndex: 'type',
          },
           {
            title: '跟进主题',
            dataIndex: 'title',
          },
           {
            title: '跟进情况',
            dataIndex: 'content',
          },
           {
            title: '跟进时间',
            dataIndex: 'follow_time',
            width:200
          },

        ],
        page: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
          limit:20,
          key: '',
          admin_id: undefined,
          company_id: undefined
        },
        recordPage: {
          start: 1,
          totalRow: 0,
          pageSize: 0,
        },
        list: [],
        followUpList:[], //跟进记录列表
        companyList: [],
        salesList: [],
        actionVisible:false
      }
    },
    mounted() {
      this.getList();
      this.getCompanyList();
      this.getSalesList();
    },
    methods: {
       /**
       * 打开客户跟进记录
       * @author lhx
       * */
    async openDialog(id) {
      console.log(id)
        let res = await Api.FollowUpPage({
          custom_id:id,
          start:1,
          limit:20
        })
        if (res && res["code"] == "0") {
          this.recordPage.totalRow = res.page.totalRow;
          this.recordPage.pageSize = res.page.pageSize;
          this.followUpList = res.page.list;
          this.actionVisible = true;
       }
      },
      /**
       * 关闭跟进记录
       * @author lhx
       * */
      cancel(){
        this.actionVisible = false
      },
      /**
       * 搜索
       * @author wheat
       * */
      search() {
        this.getList();
      },

      /**
       * 获取列表
       * @author wheat
       * */
      async getList() {
        let res = await Api.List(this.page)
        console.log(res)
        this.page.totalRow = res.page.totalRow;
        this.page.pageSize = res.page.pageSize;
        this.list = res.page.list;
      },
      /**
       * 获取公司列表
       * @author wheat
       * */
      async getCompanyList() {
        let res = await Api.GetCompanyList({});
        this.companyList = res.list;

      },
      /**
       * 获取销售列表
       * @author wheat
       * */
      async getSalesList() {
        let res = await Api.GetSalesList({});
        this.salesList = res.list;
      },
      /**
       * 分页切换
       * @author wheat
       * */
      changePage(currentPage) {
        this.page.start = currentPage;
        this.getList();
      },
     changeRecordPage(currentPage) {
       this.recordPage.start = currentPage;
     }
    },
  }
</script>

<style scoped>

</style>
