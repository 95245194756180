import request from '@/utils/request';

// 列表
export function List(params) {
  return request({
    url: '/admin/custom/custom/page',
    method: 'GET',
    params
  });
}

// 公司列表
export function GetCompanyList(params) {
  return request({
    url: '/admin/custom/company/getList',
    method: 'GET',
    params
  });
}

// 销售列表
export function GetSalesList(params) {
  return request({
    url: '/admin/collocation/admin/getSalesList',
    method: 'GET',
    params
  });
}

// 客户跟进列表
export function FollowUpPage(params) {
  return request({
    url: '/admin/custom/custom/followUpPage',
    method: 'GET',
    params
  });
}